<template>
    <div class="upload-unit">
        <p class="upload-unit-key">{{ iconKey }}：</p>
        <div class="upload-unit-right">
            <img class="upload-unit-right-icon" :src="currentIconURL" />
            <file-select class="upload-unit-right-file-select" @onFileSelect="onFileSelectSelect"></file-select>
        </div>
    </div>
</template>
  
<script>
import FileSelect from "@c/setting/organization-configuration/edit/upload-unit/file-select.vue";
import OSS from "ali-oss";

export default {
    components: {
        FileSelect,
    },

    props: {
        iconKey: String,
        initialIconURL: String,
    },

    data() {
        return {
            currentIconURL: "",
        };
    },

    created() {
        this.currentIconURL = this.initialIconURL;
    },

    watch:{
        initialIconURL(value){
            this.currentIconURL = value
        }
    },

    methods: {
        onFileSelectSelect(file) {
            if (!isPicture(file.name)) {
                this.$toast.showRed(MSG.notAPicture);
                return;
            }
            if (file.size > 500000) {
                this.$toast.showRed(MSG.pictureIsTooBig);
                return;
            }
            this.upload(file);
        },

        upload(file) {
            var client = new OSS({
                region: "oss-cn-hangzhou",
                accessKeyId: "LTAI4G3XRSugPeQn7ffN6D8u",
                accessKeySecret: "MP26RlS4Hkzg25lAsMjVZiPCdJtdvu",
                bucket: "bajie-sleep",
            });
            client
                .multipartUpload(file.name, file)
                .then((res) => {
                    var str = res.res.requestUrls[0];
                    const iconURL = str.split("?")[0];
                    this.currentIconURL = iconURL;
                    this.$emit("onUploadSucceed", this.currentIconURL);
                })
                .catch((err) => {
                    this.$toast.showRed(err);
                });
        },
    },
};
</script>
  
  
<style lang="scss" scoped>


.upload-unit {
    color: black;
    font-size: rem(14);
    margin-top: rem(18);
    display: flex;

    &-key {
        height: rem(20);
    }

    &-right {
        display: flex;

        &-icon {
            width: rem(70);
            height: rem(70);
        }

        &-file-select {
            width: rem(65);
            height: rem(32);
            align-self: flex-end;
            margin-left: rem(10);
        }
    }
}
</style>
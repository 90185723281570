<template>
  <label class="file-select">
    <!-- 样式 -->
    <div class="file-select-button">上传</div>
    <!-- 功能 -->
    <input class="file-select-input" type="file" @change="onFileChange" />
  </label>
</template>

<script>
export default {
  methods: {
    onFileChange(e) {
      this.$emit("onFileSelect", e.target.files[0]);
    },
  },
};
</script>

<style lang="scss" scoped>

.file-select {
  &-button {
    cursor: pointer;
    color: white;
    background-color: #325FAC;
    font-size: rem(14);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-input {
    display: none; //不显示
  }
}
</style>